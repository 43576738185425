import { LinearProgress, Stack, Typography } from "@mui/material";
import React, {useContext} from "react";

import { ReferenceUserContext } from './UserContextReference.js';
import { GetApiURL } from "./UserState.js";



export function LogoutPanel()
{
  setTimeout(()=> {window.location.reload();},2500);
  
  PerformLogout();
  return (
    <Stack spacing={3}>
      <Typography>You are being logged out...</Typography>
      <Typography> </Typography>
      <LinearProgress variant="indeterminate" color="primary" />
    </Stack>);
}

function PerformLogout()
{
  let opts = {
    method: 'GET',
    //headers: {},
    credentials: 'include'
  };

  const { ContextState,  SetContextState } = useContext(ReferenceUserContext);
  
  try {
    let WSUrl = GetApiURL("Verb=Logout")

    fetch(WSUrl, opts).then(function (response) {
      //console.log(response.json);
      return response.clone().json();
    })
      .then(function (body) {
        //SetCount(Count+1);
        if (body?.Status === "OK") 
        {
          SetContextState({...ContextState,
                            UserLoggedIn:body?.UserLoggedIn,
                            UserInfo:body?.UserInfo,
                            Stats:body?.Stats, 
                            AdminStats: body?.AdminStats,
                            Activity:body?.Activity,
                            LastPlayed:body?.LastPlayed
                          });
          
        }
        else {
          console.log("Body : " + body);
          SetContextState({...ContextState,UserLoggedIn:false,UserInfo:null});

        }

      });
  }
  catch (ex) {
    console.log(ex);
  }
}