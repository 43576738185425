import { DateTime } from "luxon";

const Format_Long={...DateTime.DATETIME_FULL_WITH_SECONDS, timeZoneName:undefined};

export function ToLocaleDateTimeString(d)
{
  
  return d.toLocaleString(Format_Long)
}

export function GetLocalDateAtMidnightfromEpoch(e)
{
  return DateTime.fromMillis(e).toLocal().startOf('day')
}

export function CheckFeatureWasUpdated(Features, Id)
{
  if (!Features)
  {
    return false
  }

  for (let f of Features)
  {
    if (f?.IdFeature === Id)
    {
      return true
    }
  }

  return false
}