
import React, { useContext,useEffect,useState } from "react"

import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import QueryStatsSharpIcon from '@mui/icons-material/QueryStatsSharp';
import Badge from "@mui/material/Badge";
import { ReferenceUserContext } from "./UserContextReference";
import { Card, CardContent, CardHeader, Grid, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import Box from '@mui/material/Box';
import { DateTime } from "luxon";
import RechartSingleLinePlot from "./RechartSingleLinePlot";
import { GetThemableClassName } from "./CssUtils";
import { useTheme } from "@emotion/react";



const AdminStatComponents=[ValueStat,ListStatDuration, ListStatPercentage, ValueStatWithSparkLineHourly,ValueStatWithSparkLineTS,ValueTable,ValueStatWithSparkLineTS_RunningSum];

export function AdminDrawerMenu(open)
{
  const {  ContextState } = useContext(ReferenceUserContext);
  
  let Text = "Admin Stats";

  if (ContextState?.AdminStats )
  {
    Text= "Admins Stats"; 
    return ( 
      <React.Fragment>
        <ListItemIcon 
          sx={{
            minHeight: 48,
            justifyContent: open ? 'initial' : 'center',
            px: 2.5,
          }}
        >                   
          <AdminPanelSettingsIcon/>
        </ListItemIcon>
        <ListItemText primary={Text} sx={{ opacity: open ? 1 : 0 }}/>
      </React.Fragment>
      );
  }
  else
  {
    return null;
  }
  
}

export function AdminPanel()
{
  const {  ContextState } = useContext(ReferenceUserContext);
  const StatsComponents=[];
  const Theme = useTheme()

  if (ContextState?.AdminStats?.Stats)
  {
    let KeyIndex = 0
    for (const key in ContextState.AdminStats.Stats)
    {
      if (key)
      {
        StatsComponents.push( GetStatComponent(key,ContextState.AdminStats.Stats[key],Theme,KeyIndex++));
      }
      
    };

    return (
    <React.Fragment key="AdminPanelGrid">
      <Grid  container spacing={2} >
        {
          StatsComponents.map((value,index)=>
          {
            return (value);
          })
        }
      </Grid>
    </React.Fragment>
    ); 
  } 
}

export function AdminStatusInfo(Props)
{
  const [Age,SetAge]=useState(null);
  
  let StatusColor="#FF0000";

  useEffect( ()=>{
    const Timer=setInterval(()=>{
                SetAge(Math.round((new Date() - Props.StatusDate)/ 1000));
            } ,1000);
    
      return ()=> clearInterval(Timer);
    });


  if (Age <= 30)
  {
    StatusColor="#00FF00";
  }
  else if (Age <= 60)
  {
    StatusColor="#FFF700";
  }
  else
  {
    StatusColor="#FF0000";
  }
  
  return (
    <Badge badgeContent={Age} anchorOrigin={{vertical: 'bottom',horizontal: 'right'}} max={999}>
      <QueryStatsSharpIcon fontSize='large' sx={{ color: StatusColor }} />
    </Badge>)
}

function GetStatComponent(key,Stat,Theme,KeyIndex)
{

  if (AdminStatComponents[Stat[1]])
  {

    let CardClass = GetThemableClassName(Theme,"CardTitle")
    return (
      <React.Fragment key={'AdminPanel_'+KeyIndex}>
        <Grid  item xs={12} sm={12} md={6} lg={4} xl={3}>
          <Card >
            <CardHeader className={CardClass} title={key}/>
            <CardContent>
              {AdminStatComponents[Stat[1]]( Stat)}
            </CardContent>
          </Card>
        </Grid>
      </React.Fragment>
    )
  }
  else
  {
    return ('');
  }
}

export function IsAdmin(ContextState)
{  
  return (ContextState?.AdminStats !==null );
}

function ScaleValue(v)
{
  if (v<0 || typeof v === "undefined")
  {
    return v;
  }
  else if (v === 0 || v === '0')
  {
    return 0
  }

  let Range1= Math.ceil(Math.log10(v));

  let Range = Math.pow(10,Range1);
  let Pct=10*v/Range;
  let coefs = [-2.6709 ,3.2258 ,-0.62322,0.071225 ,-0.002849]
  let S = 1
  let Ret=0
  for ( let C in coefs )
  {
    Ret += S*coefs[C];
    S*=Pct;
  }
  return 10*Ret;
}

function ValueTable(Stat)
{
  if (!Stat)
  {
    return (<Typography>No Data</Typography>)
  }
  
  try
  {
    let Cols=[]
    let Headers= Object.keys(Stat[0][0]).map((value,index)=>
    {
      Cols.push(value)
      return <TableCell><Typography fontSize={"0.75em"}>{value}</Typography></TableCell>
    })
    let rows=Stat[0].map((value,index)=>
      {
        let Values=[]
        for (let i in Cols)
        {
          let v = value[Cols[i]]
          if (v)
          {
            
            if (!isNaN(v))
            {
              let NumVal=parseInt(v,10)
              let D = DateTime.fromMillis(NumVal*1000)
              if (D.isValid)
              {
                v=D.toRelative(DateTime.fromObject(new Date()))
              }
            }
            Values.push(v);
          }
          else
          {
            Values.push('')
          }          
        }

        let cols= Values.map((v)=>{return (<TableCell><Typography fontSize={"0.65em"} >{v}</Typography></TableCell>)})
        return(<TableRow>{cols}</TableRow>)
      })
  
  return (
    <Table  key={'TableStat'+Math.random()} sx={{fontSize:'0.75em'}}>
      <TableHead/>
      {Headers}
      <TableBody>
        {rows}
      </TableBody>
    </Table>)
  }
  catch (e)
  {
    console.log(e)
    return (<Typography>Exception processing Stat Data</Typography>)
  }

}

function ValueStat(Stat)
{

  const Value = Stat[0][0]['Value'];
  const ScaledValue = ScaleValue(Value);
  return (
    <React.Fragment key={'ValueStat'+Math.random()}>
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress className="ValueStat" variant="determinate" size='5em' value={ScaledValue} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {Value}
        </Typography>
      </Box>
    </Box>
      
    </React.Fragment>
  );

}

function ValueStatWithSparkLine(Stat,XRange)
{
  const DBValues = Stat[0];
   
  let Values = [];
  let Avg = 0
  let XScaleType="Date"
  let PlotProps={Points:null,Line:{stroke: 'blue', strokeWidth: 0.5},Dot:"dot"}
  if (XRange)
  {
    Values = new Array(XRange);
    for (let index in DBValues)
    {
      let value = DBValues[index];

      if (value)
      {
        let XTick = parseInt(value.X,10)
        Values[XTick]={x : XTick,y:parseFloat(value.Y)}
        Avg += parseFloat(value.Y);
      }
    };
    Avg/=XRange;
    XScaleType="Hour"
  }
  else
  {
    //XRange = 0 for time stamped data
    let count=0;
    let MinDate = NaN
    let MaxDate = NaN
    for (let index in DBValues)
    {
      let value = DBValues[index];

      if (value)
      {
        if (isNaN(MinDate)||value.X < MinDate)  MinDate = value.X
        if (isNaN(MaxDate)||value.X > MinDate)  MaxDate = value.X
        
        Values.push({x : value.X ,y:parseFloat(value.Y)})
        Avg += parseFloat(value.Y);
        count=index;
      }      
    };
    Avg=0
    if (DBValues && DBValues[count])
    {
      Avg=DBValues[count].Y;
    }
    if (MaxDate - MinDate <= 25*3600)
    {
      XScaleType="ShortDate"
    }
  }
  PlotProps.Points=Values

  if (PlotProps.Points?.length > 30)
  {
    PlotProps.Dot=null;
  }
  const ScaledValue = ScaleValue(Avg);

  const Plot=<RechartSingleLinePlot Datas={PlotProps} XScaleType={XScaleType}/>
  return (
    <React.Fragment  key={'ValueStatWithSparkLine'+Math.random()}>
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress className="ValueStat" variant="determinate" size='5em' value={ScaledValue} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">
            {Math.round(Avg*100)/100}
          </Typography>                  
        </Box>
        
      </Box>
      {Plot}
    </React.Fragment>
  );

}

function ValueStatWithSparkLineHourly(Stat)
{
  return ValueStatWithSparkLine(Stat,24);
}

function ValueStatWithSparkLineTS(Stat)
{
  return ValueStatWithSparkLine(Stat,0);
}

function ValueStatWithSparkLineTS_RunningSum(Stat)
{
  let RunSum = 0
  let RSStats=[]
  RSStats.push([])
  RSStats.push(Stat[1])
  for (let i in Stat[0])
  {
    RunSum+=Stat[0][i].Y
    RSStats[0].push({X:Stat[0][i].X,Y:RunSum})
  }

  return ValueStatWithSparkLine(RSStats,0)
}

function ListStatDuration(Stat)
{
  return ListStats(Stat,true,true,null);
}

function ListStatPercentage(Stat)
{
  return ListStats(Stat,false,false,"%");
}

function ListStats(Stat,UseMax, UseDuration,DisplayUnit)
{

  const humanizeDuration = require("humanize-duration");
  const {Duration} = require("luxon");
  const Values = Stat[0];
  
  let MaxValue = 0

  if (! Values)
  {
    return (
      <React.Fragment  key={'ListStat'+Math.random()}>
        <Typography variant="body2">No available stat at the moment...</Typography>
      </React.Fragment>
    )
  }

  if (UseMax)
  {
    for (let index in Values)
    {
      if (Values[index])
      {
        const v=parseInt(Values[index].Value,10);
        Values[index].Value=v;
        if ( MaxValue < v)
        {
          MaxValue = v;
        }
      }

    }
  }
  else
  {
    MaxValue=100;
  }
  return (
    <React.Fragment>
      {Values.map((Value,index) =>
        {
          const CurValue = 100*Value.Value/MaxValue;
          const DurationValue = humanizeDuration(Duration.fromObject({seconds:Value.Value}),
              {largest:2,
                delimiter: " ",
                spacer: "",
                language: "shortEn",
                languages: {
                  shortEn: {
                    y: () => "Y",
                    mo: () => "M",
                    w: () => "w",
                    d: () => "d",
                    h: () => "h",
                    m: () => "m",
                    s: () => "s",
                    ms: () => "ms", 
                  },
                  
                },
              })
              
          const LabelValue = UseDuration?DurationValue:Math.round(CurValue*10)/10.+' '+DisplayUnit;

          return (
            <React.Fragment>
              <Box>
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <Typography variant="body2" noWrap>{Value.Description}</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box sx={{ width: '100%', mr: 1 }}>
                        <LinearProgress variant="determinate" value={CurValue} />
                      </Box>
                      <Box sx={{ minWidth: 90 }}>
                        <Typography variant="body2" color="text.secondary">{LabelValue}</Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </React.Fragment>
          )
        })
      }
      
    </React.Fragment>
  );

}