
import React, { useContext } from "react"

import { Card, CardHeader, CardContent, Grid, Link, Stack, Typography } from "@mui/material";


import { ReferenceUserContext } from './UserContextReference.js';
import { GetThemableClassName, GetThemableColor } from "./CssUtils.js";
import { useTheme } from "@emotion/react";

function ArtistInfo(Props)
{
  const Theme=useTheme()
  const HMDuration = require ("humanize-duration");
  const {Duration} = require("luxon")

  const DurationString = HMDuration(Duration.fromObject({seconds:Props.Artist.ArtistListenDuration}),{largest:2});
  
  const Ranks=[GetThemableColor(Theme,"gold"),"silver"," #967444"];

  return (
    //<Grid className="TopAlbum"  item xs={12} sm={4} >        
      <Card sx={{"backgroundColor":Ranks[Props.Rank]}}>
        <CardHeader title={Props.Artist.Name} />
        <CardContent className="TopAlbum">
          <Grid container>
            <Grid item xs={12}>
              <Link href={Props.Artist.URL} target='42StatsDeezer' rel="noopener">
                <img src={Props.Artist.Picture+'?Size=xs'} alt={Props.Artist.Name} />
              </Link>
            </Grid>
            
            <Grid item xs={6}>
              <Typography variant='subtitle2'>{Props.Artist.ArtistListenCount} times</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='subtitle2'>{DurationString}</Typography>
            </Grid>        
          </Grid>
        </CardContent>
        
      </Card>
    //</Grid>      
  )
}

export function TopArtists ()
{
  const Theme=useTheme()
  const {ContextState}=useContext(ReferenceUserContext);
  

  const EmptyStats=() => { return (<Typography>No Album stats yet...</Typography>);};
  let Artists=null;

  if (ContextState?.Stats && ContextState.Stats?.TopArtists && ContextState.Stats?.TopArtists[0] )
  {
    Artists = ContextState.Stats.TopArtists[0].map((value,index) => 
      {
        
        return (
          
            <ArtistInfo key={"ArtistInfo"+index} Artist={value} Rank={index} />
          
          );
      }
    )
  }
  
  return (
    <Card variant="outlined" >
      <CardHeader className={GetThemableClassName(Theme,"CardTitle")} title=  "Top Artists">
      </CardHeader>
      <CardContent>
        <Stack direction="row" justifyContent="space-evenly" alignItems="baseline" spacing={2}>
              {Artists?Artists:EmptyStats()}              
        </Stack>
      </CardContent>
      
    </Card>    
  )

}