import React, { useContext, useEffect, useState } from "react";
import {  Badge, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, ListItemIcon, ListItemText, Radio, RadioGroup, Skeleton, Stack, Switch, Typography } from "@mui/material";
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';

import { ReferenceUserContext } from "./UserContextReference";
import { GetApiURL, GetUserState } from "./UserState";
import { CheckFeatureWasUpdated } from "./42StatsDateTimeUtils";

function BoolPref (Props)
{
  const value = Props?.PrefValue;
  const HasValue = (Props && Props.PrefValue);
  const CallBackFn = Props?.OnChangeCallback;
  const PrefLevel = value?.PrefLevel?value.PrefLevel:0
  const SubPrefs = value?.SubPrefs

  if (value)
  {
    if (SubPrefs)
    {
      console.log("SubPref populated",SubPrefs)
    }
    return (<>
        <Stack key={'GridPref'+value.PrefId} direction="column" {...Props} spacing={1} border={PrefLevel===1?1:0} sx={{  borderColor: 'primary.main', borderRadius: '6px'}}>
          <FormControlLabel 
            label={HasValue? <Typography color="primary" variant={"h"+(PrefLevel+5)}>{value?.PrefTextEn}</Typography>:<Skeleton variant="rectangular" width={200}/>}
            control={HasValue? <Switch checked={value.Value==="1"} onChange={(e,v)=>{CallBackFn(e,v,value)}}></Switch>:<Skeleton width={100}/>}
          />
          <Stack direction="row" spacing={2} marginLeft="6px">
            <div style={{width:"35px"}}/>
            {SubPrefs?SubPrefs.map((v)=>{return  <BoolPref PrefValue={v}  OnChangeCallback={CallBackFn} index={Props.index} />})
            :null}              
          </Stack>
          
        </Stack>
        <div style={{width:"35px", height:"10px"}}/>
        </>
    )
  }
  else
  {
    return null;
  }
}

function BuildPrefsTree(PrefData,Level = 1, CurIndex = 0)
{
  let index = CurIndex
  let PrefsLevelN = []
  while (index < PrefData.length)
  {
    let P = PrefData[index]

    if (P.PrefLevel === Level)
    {
      PrefsLevelN.push(PrefData[index])
    }
    else if (P.PrefLevel > Level)
    {
      let [SubPrefs,I]=BuildPrefsTree(PrefData,Level+1,index)
      PrefData[index-1].SubPrefs=SubPrefs
      index = I-1
    }
    else
    {
      return [PrefsLevelN,index]
    }
    index++
  }

  if (Level===1)
  {
    PrefsLevelN.Hash=PrefData.Hash
    PrefsLevelN.OnChangeCallback=PrefData.OnChangeCallback
  }

  console.log("BuildPref terminal exit",Level,PrefsLevelN,index)
  return [PrefsLevelN,index]
}

function DarkModePref (Props)
{
  const value = Props?.PrefValue;
  const HasValue = (Props && Props.PrefValue);
  const CallBackFn = Props?.OnChangeCallback;
  const PrefLevel = value?.PrefLevel?value.PrefLevel:0;

  if (value)
  {
    return (
        <FormControl sx={{border: PrefLevel===1?1:0, borderColor: 'primary.main', borderRadius: '6px'}}>
          <FormControl> {HasValue? <Typography color="primary" variant={"h"+(PrefLevel+5)}>{value?.PrefTextEn}</Typography>:<Skeleton variant="rectangular" width={200}/>}</FormControl>
          <RadioGroup row value={value.Value} onChange={(e,v)=>{CallBackFn(e,v,value)}} >
            <FormControlLabel value="0" control={<Radio />} label="Browser Default" labelPlacement="top"/>
            <FormControlLabel value="1" control={<Radio />} label="Light" labelPlacement="top"/>
            <FormControlLabel value="2" control={<Radio />} label="Dark" labelPlacement="top"/>
          </RadioGroup>
        </FormControl>
     
    )
  }
  else
  {
    return null;
  }
}
function EmptyPrefs()
{
  return ([BoolPref(null),BoolPref(null)])
}

export function PreferencesPanel(Props)
{
  const {ContextState,SetContextState} = useContext(ReferenceUserContext)
  const [Prefs,SetPrefsData]=useState(null);
  
  function OnPrefChangeCallback(Event,NewValue,SourcePref)
  {
    
    console.log("DBG OnPrefChange",SourcePref,NewValue)
    SetPrefs(SourcePref.PrefId,NewValue);

    if (SourcePref.PrefId === 6)
    {
      
      function UpdateContextState(NewInfo)
      {
        if (NewInfo && typeof NewInfo == "object" && (! NewInfo.then))
        {
          if (ContextState?.ModalShown)
          {
            NewInfo.ModalShown=true;    
          }
          
          SetContextState(NewInfo);
        }
        
      }
      
      GetUserState(UpdateContextState)
  
    }
    
    return true;
  }
  
  function GetPrefs()
  {
    let WSUrl = GetApiURL( "Verb=GetPrefs");
    var opts = {
      method: 'GET',
      //headers: {},
      credentials: 'include'
    };
    let PrefsData=null;
    try
    {
      fetch(WSUrl, opts).then(function (response) {
        //console.log(response.json);
        return response.clone().json();
      })
        .then(
          function (body) 
          {
            if (body?.Status==="OK") 
            {
              if (body?.Prefs)
              {
                
                PrefsData=body.Prefs;
                PrefsData.Hash=body.PrefHash;
                PrefsData.OnChangeCallback = OnPrefChangeCallback
              }
              else
              {
                PrefsData=null;
              }
              if (PrefsData?.Hash !== Prefs?.Hash)
              {
                [PrefsData]=BuildPrefsTree(PrefsData)
                SetPrefsData(PrefsData);
              }
            }
          }
        );
    }
    catch (ex) {
      console.log(ex);
    }    ;
  }

  function SetPrefs(PrefId, NewValue)
  {
    let WSUrl = GetApiURL("Verb=SetPrefs");
    var opts = {
      method: 'POST',
      //headers: {},
      credentials: 'include',
      'Content-Type': 'application/x-www-form-urlencoded',
      body: JSON.stringify({PrefId:PrefId, NewValue:NewValue})
    };
    let PrefsData=null;
    try
    {
      fetch(WSUrl, opts).then(function (response) {
        //console.log(response.json);
        return response.clone().json();
      })
        .then(
          function (body) 
          {
            if (body?.Status==="OK") 
            {
              if (body?.Prefs)
              {
                
                PrefsData=body.Prefs;
                PrefsData.Hash=body.PrefHash;
                PrefsData.OnChangeCallback = OnPrefChangeCallback
              }
              else
              {
                PrefsData=null;
              }
              if (PrefsData?.Hash !== Prefs?.Hash)
              {
                [PrefsData]=BuildPrefsTree(PrefsData)
                SetPrefsData(PrefsData);
              }
            }
          }
        );
    }
    catch (ex) {
      console.log(ex);
    }    ;
  }
  
  useEffect(GetPrefs);
  let PrefRender = null;
  if (Prefs)
  {
     PrefRender = Prefs.map((value,index)=>{
        switch (value.Type)
        {
          case 0: 
            return <BoolPref key={"Pref"+index} PrefValue={value} index={index} OnChangeCallback={Prefs.OnChangeCallback}/>

          case 1:
            return <DarkModePref key={"Pref"+index} PrefValue={value} index={index} OnChangeCallback={Prefs.OnChangeCallback}/>

          default:
            return (
              <Grid item> 
                <Typography>
                  Unexpected Preference type {value.Type} for Pref : {value.Name}
                </Typography>
              </Grid>)
        }
      
    });

    //return PrefRender;
  }
  return (<>
    <FormControl component="fieldset" variant="outlined">
      <FormLabel><Typography color="primary" variant="h3" gutterBottom={1}>42Stats Preferences</Typography></FormLabel>
      <FormGroup>
        {PrefRender?PrefRender:EmptyPrefs()}
      </FormGroup>
      
    </FormControl>
    </>)
  
}

export function PreferenceDrawerMenu(open)
{
  const {  ContextState } = useContext(ReferenceUserContext);
  
  let Text = "Preferences";
  let FeatureBadgeUpdated = CheckFeatureWasUpdated(ContextState?.Features,2)

  if (ContextState?.UserInfo && ContextState.UserInfo.id)
  {
    
    return ( 
      <React.Fragment>
        <ListItemIcon
          sx={{
            minHeight: 48,
            justifyContent: open ? 'initial' : 'center',
            px: 2.5,
          }}
        >                   
        <Badge color="warning" invisible={!FeatureBadgeUpdated}  variant="dot">
          <SettingsTwoToneIcon/>
        </Badge>
        </ListItemIcon>
        <ListItemText primary={Text} sx={{ opacity: open ? 1 : 0 }}/>
      </React.Fragment>
      );
  }
  else 
  {
    return null;
  } 
}