import React,{ useState, useContext, useEffect } from 'react';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PolicyTwoToneIcon from '@mui/icons-material/PolicyTwoTone';
import LogoutIcon from '@mui/icons-material/Logout';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { styled } from '@mui/material/styles';
import Brightness4OutlinedIcon from '@mui/icons-material/Brightness4Outlined';


import Tos from './Tos.js'
import UserPanel from './UserPanel.js'
import { MeDrawerMenu } from './UserProfile.js'
import { AdminDrawerMenu, AdminPanel, AdminStatusInfo  } from './AdminStats.js';
import { ReferenceUserContext } from './UserContextReference.js';
import UserHistoryPanel, { UserHistoryMenu } from './UserHistory.js';
import { LogoutPanel } from './LogoutPanel.js';
import { GetUserState } from './UserState.js';
import { PreferenceDrawerMenu, PreferencesPanel } from './Preferences.js';
import { Alert } from '@mui/material';
import Feedback, { FeedbackMenu } from './FeedbackForm.js';
import UserHistory2Panel, { UserHistory2Menu } from './UserHistory2.js';



const MDOE_MY_ACCOUNT = 0
const MODE_PREFERENCES = 1
const MODE_ADMIN_STATS = 2
const MODE_USER_HISTORY = 3
const MODE_USER_HISTORY2 = 4
const MODE_USER_FEEDBACK = 5

const MODE_TOS=100
const MODE_LOGOUT=200

const drawerWidth=240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const  GettersList=[MeDrawerMenu,PreferenceDrawerMenu,AdminDrawerMenu,UserHistoryMenu,UserHistory2Menu,FeedbackMenu];



const IconsList2=[<PolicyTwoToneIcon/>]; 
const IconsList3=[<LogoutIcon/>]; 


export default function Main(Props) 
{
  const { ContextState , SetContextState} = useContext(ReferenceUserContext);
  const [ Mode, SetMode ] = useState(0);
  const [ open, setOpen ] = useState(false);
  
  function UpdateContextState(NewInfo)
      {
        if (NewInfo && typeof NewInfo == "object" && (! NewInfo.then))
        {
          if (ContextState?.ModalShown)
          {
            NewInfo.ModalShown=true;    
          }
          
          SetContextState(NewInfo);
        }
        
      }

  useEffect (
    ()=>
    {
      let f=UpdateContextState
      let timer=setTimeout(() => {
        GetUserState(f);
      }, 90000);
      return () => clearTimeout(timer);
    }
  )

  function GetBodyComponent(Mode,UpdateContextCallBack)
  {
    let StateMgr=<></>; //<AutoRefreshUserState CallBack = {UpdateContextState} />
    let PanelContent=<></>
    switch (Mode)
    {
      case MDOE_MY_ACCOUNT:
        PanelContent = <UserPanel CallBack={UpdateContextCallBack} key="UserPanel" {...Props}/>;
        break;
      case MODE_PREFERENCES:
        PanelContent = <PreferencesPanel key="PrefsPanel" {...Props}/>
        break;
      case MODE_ADMIN_STATS:
        PanelContent = (<AdminPanel key="AdminPanelkey" />);
        break;
      case MODE_TOS:
        PanelContent = (<Tos/>);
        break;
      case MODE_USER_HISTORY:
        PanelContent = (<UserHistoryPanel key="UserHistoryPanel" />);
        break;
      case MODE_USER_HISTORY2:
        PanelContent = (<UserHistory2Panel key="UserHistory2Panel" />);
        break;
      case MODE_USER_FEEDBACK:
        PanelContent = (<Feedback key="FeedbackPanel"/>);
        break;
      case MODE_LOGOUT:
        PanelContent = (<LogoutPanel />);
        break;
      default:
        PanelContent = (<div>Unexpected Mode {Mode}</div>);
    }
    return (<>{StateMgr}<div className='mui-container-fluid'><div className='mui-row'> {PanelContent}</div></div></>)
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  
 
  let ShowStatsStatus = ContextState?.AdminStats;
  let LastStatsDate = null;
  if (ShowStatsStatus)
  {
    LastStatsDate = new Date (1000*ContextState.AdminStats.LastRunDate);    
  }

  let Notice=null;
  if (ContextState?.Notice )
  {
    Notice= <Alert variant='filled' severity='error'>
              <Typography variant='h4'>{ContextState?.Notice}
            </Typography>
            </Alert>
  }
  
  let BannerText="Welcome to 42Stats"
  if (ContextState?.UserLoggedIn)
  {
    Props.SetDarkMode(ContextState.Theme,false);
    BannerText="My Deezer Stats"
  }
  
  return (
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}> 
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
          
            <Typography variant="h6" noWrap  sx={{ flexGrow: 1 }}>
              {BannerText}
            </Typography>
            <Brightness4OutlinedIcon onClick={Props.ChangeDarkMode} />
            {ShowStatsStatus?<AdminStatusInfo StatusDate={LastStatsDate} />:''}
            
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open} >
           <DrawerHeader>
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon/>
              </IconButton>
          </DrawerHeader>
          
          <Divider />
          <Box sx={{ overflow: 'none' }}>
            <List>
              {GettersList.map((text, index) => 
                
                {
                  const component=GettersList[index](open);
                  if (component)
                  {
                    return(
                      <ListItem key={"Menu"+index} disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                          }}                      
                          selected={Mode === index}
                          onClick={(event) => SetMode(index)}>
                          {component}
                        </ListItemButton>
                      </ListItem>
                      )
                  }
                  else
                  {
                    return null;
                  }
                }
              )}
            </List>
            <Divider />
            <List>
              {['Terms Of Service'].map((text, index) => (
                <ListItem key={text} disablePadding sx={{ display: 'block' }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  
                  selected={Mode === 100+index}
                  onClick={(event) => SetMode(100+index)}>
                  <ListItemIcon
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                    {IconsList2[index]}
                  </ListItemIcon>
                  <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <Divider/>
            <List>
            {['Logout'].map((text, index) => {

                if (ContextState?.UserLoggedIn )
                {
                  return (<ListItem key={text} disablePadding sx={{ display: 'block' }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  
                  selected={Mode === 100+index}
                  onClick={(event) => SetMode(200+index)}>
                  <ListItemIcon
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                    {IconsList3[index]}
                  </ListItemIcon>
                  <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>)
                }
                else
                {
                  return (null);
                }
              })
            }
            </List>
          </Box>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3 , paddingTop : '72px'}}>
          {Notice}
          {GetBodyComponent(Mode, UpdateContextState)}  
          
        </Box>
        
      </Box>    
  );
}

