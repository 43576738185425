import { Button, Grid, LinearProgress, Stack, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import React, { useState, useContext, useEffect } from "react";
import { ReferenceUserContext } from './UserContextReference.js';
import { DeezerAppUrl, GetUserState } from "./UserState.js";
import { HorizontalTrackSwipe } from "./TrackSwipe.js";

export default function LoginButton(Props) {
  
  const { ContextState } = useContext(ReferenceUserContext);
  const [AskLogin, SetAskLogin] = useState(null);
  const [IntervalHandle, SetIntervalHandle] = useState(null);
  const [LastPlayed,SetLastPlayed]=useState(null)
  
  useEffect(()=>
  {
    if (ContextState)
    {
      SetLastPlayed(ContextState.LastPlayed)
    }
  },[ContextState])
  
    /*setInterval(() => {console.log("Initial timer")
      CheckIsLoggedIn();
      }, 30000));*/
      
  

  /*useEffect( ()=>{
    const Timer=setInterval(()=>{
                CheckIsLoggedIn();
            } ,30000);
    
      return ()=> {
        console.log("interval no more")
        clearInterval(Timer)
      };
    });*/

  function HandleLoginButtonClick() {
    if (!ContextState || !GetAuthToken()) {
      SetAskLogin(true);
    }
  }

  function GetAuthToken() {
    if (!ContextState) {
      return CheckIsLoggedIn(Props.CallBack);
    }
    return false
  }

  function CheckIsLoggedIn() 
  {  
    let ret =GetUserState(Props.CallBack);
    return ret?.UserLoggedIn===true;
  }

  if (AskLogin) 
  {
    if (!CheckIsLoggedIn())
    {
      window.open(DeezerAppUrl,"_self");
    }    
    SetAskLogin(false);
    clearInterval(IntervalHandle);
    SetIntervalHandle ( setTimeout(() => {console.log("settimer to 1s")
      CheckIsLoggedIn();
    }, 1000));
    console.log("here");
  }
  else 
  {
    console.log("render Button " + ContextState);
    GetAuthToken(Props.CallBack);
    console.log("render Button " + ContextState);
    const LoginButton = <Button  variant="outlined" onClick={HandleLoginButtonClick}>Click to login with Deezer</Button>
    const Waitbar = <Stack spacing={3}>
                      <Typography>...</Typography>
                      <LinearProgress variant="indeterminate" color="primary" />
                    </Stack>
    console.log("Context lastplayed",ContextState?.LastPlayed)

   
    return (
        <Grid className="BlueText" container spacing={3} justifyContent="center">
          <Grid  item xs = {12} sm={9} md={6} lg={3}> 
            {ContextState?LoginButton:Waitbar}
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={12}>
            <Typography  variant="caption">and look at your listening stats in real time.</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">42stats will record your listening history (from 1st connexion to 42stats) with no time limit (for now)</Typography>
          </Grid>

          <Grid  item xs={12} justifyItems={"center"}>
            
            <Grid container justifyItems={"center"} spacing={2}>
              <Grid item xs={12} alignSelf="start">
                <Typography variant="caption">it will also generates some playlists for you:</Typography>
              </Grid>
              <Grid item  xs={12} >
                <Table sx={{color:"#1976d2"}} >
                  <TableBody>
                    <TableRow  >
                      <TableCell>
                        <Typography className="BlueText" variant="h6">Daily Favorites</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className="BlueText" variant="body2">A mix of your favorite tracks & favorite albums, sorted so that it always feel new</Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography className="BlueText" variant="h6">Daily Discoveries (experimental)</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className="BlueText" variant="body2">A mix of tracks you did not listen, and may like (my feeling of what flow should be)</Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                  
                </Table>
              </Grid>
             
            </Grid>
            <Grid item xs={12}><Typography variant="h5">Recent 42stats Users listened tracks</Typography> </Grid>
            <Grid item xs={12}>
              <HorizontalTrackSwipe className="swiper"  Tracks = {LastPlayed}/>
            </Grid>
          </Grid>
          
          
        </Grid>
      
    )
  }

  console.log('Button no more...',IntervalHandle);
  return (<></>)
}

