import React from "react";
import {   CartesianGrid, XAxis, YAxis, ResponsiveContainer, Tooltip, ComposedChart, Line, Area, Legend } from 'recharts';
const {DateTime}= require ('luxon')
  
function DateTickFormater(Value,Index)
{
  
  const r = DateTime.fromSeconds(Value).toFormat("LLL yy")
  return r

}

function ShortDateTickFormater(Value,Index)
{
  
  const {DateTime}= require ('luxon')
  const r = DateTime.fromSeconds(Value).toFormat("HH:mm")
  return r

}

function YAxisTickFormatter(Value,Index)
{
  let Order = 0

  if (Value > 0)
  {
    Order= Math.max(0, 3-Math.floor(Math.log10(Value)))
  }
  return Value.toFixed(Order)
}

function LegendFormatter(value,entry)
{
  return entry?.payload.Name
}

function ToolTipLabelFormatter(value,entry, props)
{
  let d=DateTime.fromSeconds(value, {locale:"UTC"}).toLocal()

  if (value < 1578873600 || d.invalid)
  {
    return value
  }
  return d.toFormat("dd LLL yy HH:mm")
}

export default function ComposedPlots(Props)
{
  const Datas= Props.Datas
  const XScaleType = Props.XScaleType
  let Formater=false
  
  if (XScaleType==="Hour")
  {
    Formater = false
  }
  else if (XScaleType==="Date")
  {
    Formater = DateTickFormater
  }
  else if (XScaleType==="ShortDate")
  {
    Formater = ShortDateTickFormater
  }
  
  if (Datas )
  {
    let Scatters=Datas.map((value)=>{
        if (value)
        {
          let PlotValue=Array.from(value.Points)
          let DotType = value.Dot
          let FillValue = value.FillValue
          let KeyValue = "Scatter_"+Math.random()
          let Name = value.LegendName

          if (FillValue)
          {
            return <Area type="monotone" key={KeyValue} dataKey="y" data={PlotValue} stroke={value?.Line} fill={FillValue} fillOpacity={0.1} dot={DotType} Name={Name}/>
          }
          else
          {
            return <Line type="monotone" key={KeyValue} dataKey="y" data={PlotValue}  line={value?.Line} dot={DotType} Name={Name}/>
          }
        }
        return <></>
    })

    
    
    return <ResponsiveContainer width={"100%"} height={200} >
            <ComposedChart data={Datas[0].Points}  margin={{ top: 5, right: 10, bottom: 5, left: 10 }} >
              {Scatters}
              <Legend formatter={LegendFormatter}/>
              <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
              <XAxis type="number" dataKey="x" domain={['dataMin', 'dataMax']} tickFormatter={Formater} />
              <YAxis type="number" dataKey="y" domain={['dataMin', 'dataMax']} tickFormatter={YAxisTickFormatter}/>
              <Tooltip labelFormatter={ToolTipLabelFormatter}/>
            </ComposedChart>
          </ResponsiveContainer>
  }
  else
  {
    return <></>
  }
}