
import React, { useEffect, useState } from "react";

export const DeezerAppUrl = GetApiURL();


export function AutoRefreshUserState(Props)
{
  const [CallBackFn] = useState(Props.CallBack);
  const [CachedState, SetCachedState] =  useState({LastView:0,Value:null});

  useEffect (
    () =>
    {
      
      let ret = GetUserState(CachedState,SetCachedState);
      console.log('cached date'+CachedState.LastView);

      
      if (ret?.UserLoggedIn===true)
      {
        setTimeout(()=>{AutoRefreshUserState(CallBackFn)});
      }

      if (CallBackFn)
      {
        CallBackFn(ret);
      }
    },
    [CallBackFn,CachedState]);
  return (<></>);
}


export function GetApiURL(Service)
{
  let Ret= "https://42stats.fr/Server";
  if (Service!==null && typeof Service !== "undefined")
  {

    let S = window.location.search
    const SP = new URLSearchParams(S)

    let Imp = ''

    if (SP.has('Imp'))
    {
      Imp = "&Imp="+SP.get('Imp')
    }
    Ret +=  "/ws-42stats.php?appcall="+ (Math.random()).toString().replace("0.","")+"&"+Service+Imp
  }
  else
  {
    Ret+="/ws.php"
  }
  return Ret
}

export function GetUserCachedFile (filename)
{
  if (filename && typeof filename === "string"  && filename.length > 1)
  {
    let Imp=null
    if (window.location.search)
    {
      Imp = new URLSearchParams(window.location.search).get('Imp')
      
    }
    let  RetString = "https://42stats.fr/Server/cache.php?resource="+filename
    if (Imp)
    {
      RetString += '&Imp=' + Imp
    }

    return RetString
  }

  return null;

}

let UserStatePending =0
export function GetUserState(CallBackFn)
{
  var opts = {
    method: 'GET',
    //headers: {},
    credentials: 'include'
  };

  try {
    let WSUrl = GetApiURL ("Verb=CheckLogin");

    console.log("userstatecounter",UserStatePending)
    if (!UserStatePending)
    {
        UserStatePending=true
        fetch(WSUrl, opts).then(function (response) {
        try{
          return response.clone().json();               
        }
        catch(ex)
        {
          UserStatePending=false
          CallBackFn(  { 
            InvalidJson:response.clone().text(),
            Status:false
          });
        }
    })
      .then(function (body) {
        //console.log("Processing Body : "+body)
        UserStatePending=false
        if (body?.Status === "OK" && CallBackFn) 
        {                       
          CallBackFn(body);
        }
        else if (CallBackFn)
        {
          CallBackFn( {InvalidBodyContent:body,
            UserLoggedIn:false,
            UserInfo:null,
            Stats:null, 
            AdminStats: null,
            Activity:null});            
        }

      });
      
    }
  }
  catch (ex) {
    CallBackFn( {ExceptionDetails:ex,
      UserLoggedIn:false,
      UserInfo:null,
      Stats:null, 
      AdminStats: null,
      Activity:null});
  }

}

