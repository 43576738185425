import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { ReferenceUserContext } from "./UserContextReference";
import { Legend, ResponsiveContainer, Tooltip, Treemap } from "recharts";
import { Waypoint } from "react-waypoint";
import { useTheme } from "@emotion/react";
import { GetThemableClassName } from "./CssUtils";

export default function GenreStats (Props)
{
  const {ContextState}=useContext(ReferenceUserContext);
  
  const [ChartsData] = useState (ContextState?.Stats?.TestCharts);
  const [Animate, SetAnimate]=useState(false);
  const [Comp, SetComp]=useState( )
  const h=400;
  const Theme=useTheme()
  
  useEffect(()=>
  {
    function BuilChart ()
    {
      if (ChartsData)
      {
        let RunSum = 0
        for (let i in ChartsData)
        {
          RunSum+=ChartsData[i].Value
          ChartsData[i].RunSum=RunSum

        }
        let PlotData = ChartsData.filter( (v) => { return v.RunSum <= 0.9*RunSum}).map((value)=>
                        {
                          return {'name':value.Name,'children':[{'name':value.Name,'value':value.Value}], fill:value.Color}                          
                        })
        SetComp(<ResponsiveContainer width="100%" height ={h}>
                <Treemap  
                  data={PlotData}
                  dataKey="value" 
                  aspectRatio={1+Math.sqrt(5)/2}
                  isAnimationActive={Animate}
                  >
                  <Tooltip />
                  <Legend />
                  </Treemap>
              </ResponsiveContainer>)
      }
      else
      {
        SetComp(<Typography>No Data or not enough data yet</Typography>)
      }
    }

    setTimeout(BuilChart,1)
  },[Animate,ChartsData]);
  function StartAnim () { SetAnimate(true)}
  function StopAnim () { SetAnimate(false)}
  


  return <>
          <Waypoint 
            onEnter={StartAnim}
            onLeave={StopAnim} />
          <Card variant="outlined">
            <CardHeader className={GetThemableClassName(Theme,'CardTitle')} title="Last 30 Days music genre"/>
            <CardContent>
              {Comp}
            </CardContent>
          </Card>
          </>
}