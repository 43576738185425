import React from "react";
import { Badge, Button, Grow, ListItem, ListItemButton, ListItemIcon, Stack, Typography } from "@mui/material";
import Favorite from "@mui/icons-material/Favorite";
import { ArtistInfo } from "./ArtistInfo";
import { ToLocaleDateTimeString } from "./42StatsDateTimeUtils";

export function GoToTrack(URL)
{
  if (typeof URL !== "undefined" && URL)
  {
    window.open(URL,"Deezer42Stats");
  }
}

export function TrackListItem(Props)
{
  
  return (
    <ListItem >
      <ListItemButton onClick={()=>{GoToTrack(Props.Track.TrackURL)}}>
        <ListItemIcon>
          {(Props.Track?.IsFav?<Favorite sx={{ color: "red" }}></Favorite>:null)};
          <img className="TrackMiniImage" src={Props.Track.Cover+'?Size=small'} alt={Props.Track.TrackTitle} />    
        </ListItemIcon>
        {Props.Track.TrackTitle}
      </ListItemButton>
    </ListItem> 
   
  )
}

export function TrackHistory(Props)
{

  const { DateTime } = require ('luxon');
  let ListenDate =  DateTime.fromJSDate (new Date(Props?.Track.TrackTimeStamp*1000));
  
  return (
    <Button onClick={() => {GoToTrack(Props.Track.TrackURL)}} sx={{"alignItems":"left"}}>
      <Stack direction="row" spacing={2} width={"100%"}>
        
          <Badge badgeContent={Props?.Track?.ListenCount} color="info" max={100000} >
            <TrackImage Size='medium' Cover={Props?.Track?.AlbumImage} Text={Props?.Track?.TrackTitle} />
          </Badge>
          <Stack direction='column' spacing={3}>
            <Typography >{Props?.Track?.TrackTitle}</Typography>
            <ArtistInfo key={"ArtistInfo"+Math.random()} TrackInfo={{Picture:Props?.Track?.ArtistPicture,Name:Props?.Track?.ArtistName}} Size='small' />
          </Stack>
          <Stack direction='row' spacing = {2}>
            <Stack spacing={3}>
              <Typography >Listened : {ToLocaleDateTimeString( ListenDate)}</Typography>
              
            </Stack>
          </Stack>
      </Stack> 
    </Button>
   
  )
}

export function TrackHistory2(Props)
{

  return (
    
      <Button flex={Grow} onClick={() => {GoToTrack(Props.Track.TrackURL)}} sx={{"alignItems":"left","display":"flex", marginTop:'4px'}}>
        <Stack direction="row" spacing={2} flex={1}>
          
            <Badge badgeContent={Props?.Track?.ListenCount} color="info" max={100000} >
              <TrackImage Size='medium' Cover={Props?.Track?.AlbumImage} Text={Props?.Track?.TrackTitle} />
            </Badge>
            <Stack direction='column' spacing={3}>
              <Typography >{Props?.Track?.TrackTitle}</Typography>
              <ArtistInfo key={"ArtistInfo"+Math.random()} TrackInfo={{Picture:Props?.Track?.ArtistPicture,Name:Props?.Track?.ArtistName}} Size='small' />
            </Stack>
            
        </Stack> 
      </Button>
    
  )
}


export function TrackImage(Props)
{
  let Size = (Props?.Size?Props.Size:"small");
  let SrcURL = Props?.Cover+'?size='+ Size;

  return (<img className={Props.Class} src={SrcURL} alt={Props?.Text} />)
}