import { List, Typography } from "@mui/material";
import React, { useContext } from "react"
import { TrackListItem } from "./Track";

import { ReferenceUserContext } from "./UserContextReference";

export function TrackList(Props)
{
  const {  ContextState } = useContext(ReferenceUserContext);
  const Tracks = ContextState?.Stats?.LastTrack;
  const MaxTracks = Props.MaxTracks;
  const SkipFirst = Props.SkipFirst;
  if (Tracks)
  {
    return (
      <List>
      {
        Tracks.map(
          (value,index)=>{
            if (SkipFirst && !index)
            {
              return (null);
            }
            else if (index <= MaxTracks )
            {  return (
                  
                  <TrackListItem key={"TrackList"+index} Track= {value}/>
                  
                )
            }
            else
            {
              return (null);
            }
        })
      }
      </List>
    );
  }
  else
  {
   return ( <Typography >
      No Tracks so far. Give us a little time to get your history from deezer, and your stats will show.
      Come back in a few minutes..... (~45 at the moment)
    </Typography>)
  }
}