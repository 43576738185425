import React, { useContext } from "react";
import { ReferenceUserContext } from "./UserContextReference.js";
import LoginButton from "./LoginButton.js";
import UserProfile from "./UserProfile.js";
import { LoginInformationModal } from './LoginInformationModal.js'

export default function UserPanel(Props) {

  const {  ContextState } = useContext(ReferenceUserContext);

  if (ContextState?.UserLoggedIn) 
  {
    return (
      <>
        <UserProfile {...Props}/>
        <LoginInformationModal ShowModal={false && !ContextState.ModalShown}/>
      </>
    )
  }
  else {
    return (
      <LoginButton CallBack={Props.CallBack} />
    )
  }

}