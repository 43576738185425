import { Link, Stack, Typography } from "@mui/material";
import React from "react";

function ArtistSmall(Props)
{
  
  return(<React.Fragment>
    <Link href={Props.TrackInfo.ArtistURL} target="42StatsDeezer" rel="noopener">
    <Stack direction='row' spacing={2}>
      <img src={Props.TrackInfo.Picture+'?size=small'} alt="artist"/>
      <Typography variant='body1' className="ArtistSmall">{Props.TrackInfo.Name}</Typography>      
    </Stack></Link>
  </React.Fragment>);
}
export function ArtistInfo(Props)
{
  const SizeRender = [ArtistSmall] ;
  let Render =null;

  if (Props.Size)
  {
    Render=SizeRender[Props.Size];
  } 

  if (!Render)
  {
    Render = SizeRender[0];  
  }
  return(
  <React.Fragment>
    {Render(Props)}
  </React.Fragment>)
}