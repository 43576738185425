import { Pagination, Navigation,Autoplay } from 'swiper/modules'

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';

import { useEffect, useState } from 'react';
import { GoToTrack, TrackImage } from './Track.js'
import { Stack } from '@mui/material';

function GetTrackSlide(TrackInfo)
{
  const Cover=TrackInfo?.Cover

  let BgInfo = "#00FFFFFF"
  
  if (TrackInfo?.Colors)
  {
    if (TrackInfo.Colors.length === 1)
    {
      BgInfo = TrackInfo?.Colors[0]
    }
    else
    {
      let Steps = TrackInfo.Colors.map((v,index)=>{ return ' '+v+ ' ' + (index * 100 / (TrackInfo.Colors.length-1))+ "%"})
      
       BgInfo = 'linear-gradient(90deg, '+ Steps.join(",",Steps) + ')';       
    }
  }
  return  <SwiperSlide className='TrackMediumImage' key={"CoverHSwipe"+Math.random()}>
              <Stack>
                <TrackImage Class="SwiperImage" Cover={Cover} Size="med" Text={TrackInfo?.TrackTitle} />
                <div style={{background:BgInfo, height:"6px"}}></div>
              </Stack>
          </SwiperSlide>
}
 export function HorizontalTrackSwipe(Props)
{

  const [Slides,SetSlides]=useState(null)

  useEffect(()=>
  {
    if (Props.Tracks)
    {
      SetSlides(Props.Tracks.map ((value)=>{
      return GetTrackSlide(value)}))
    }
    else
    {
      SetSlides(null)
    }
  },[Props.Tracks])
  
  if (Props?.Tracks)
  {
    
    return (
      <Swiper
        //spaceBetween={10}
        slidesPerView={'auto'}
        autoHeight={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          dynamicBullets: true,
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        breakpoints={{
          140: {
            slidesPerView: 1,
            spaceBetween: 5,
          },
          700: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 10,
          },
          1600: {
            slidesPerView: 6,
            spaceBetween: 10,
          },
        }}
        onClick={(ev)=>{
                    const ImgIndex = ev.clickedSlide.swiperSlideIndex
                    const TrackUrl = Props?.Tracks[ImgIndex]?.TrackURL
                    GoToTrack(TrackUrl)
                  }
                }
      >
        {Slides}
      </Swiper>
    );
  }
  else
  {
    return null
  }
}