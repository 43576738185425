import React, { useContext } from "react";

import DialogContent from "@mui/material/DialogContent";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { IconButton } from "@mui/material";
import { Typography } from "@mui/material";

import { ReferenceUserContext } from './UserContextReference.js';
import { ToLocaleDateTimeString } from "./42StatsDateTimeUtils.js";

export function LoginInformationModal(props)
{
  const [open, setOpen] = React.useState(props.ShowModal);
  const {  ContextState } = useContext(ReferenceUserContext);

  const HandleClose = function()
  {
    setOpen(false);
  }

  if (ContextState)
  {
    ContextState.ModalShown=true;
    const { DateTime } = require ('luxon');

    const LastLoginDate = DateTime.fromJSDate(new Date(ContextState.Activity?.LastLogin*1000));  

    return (
      <Dialog onClose={HandleClose} open={open}>
        <DialogTitle >
          <Typography>
            Welcome Back {ContextState?.UserInfo?.name}
          </Typography>
          <IconButton
              aria-label="close"
              onClick={HandleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
          </IconButton>
          
        </DialogTitle>
        <DialogContent>
          <Typography>
            Last Login was on {ToLocaleDateTimeString(LastLoginDate)}
          </Typography>
        </DialogContent>
      </Dialog>
    );
  }
  else
  {
    return (<></>);
  }
}