
import React, { useContext, useState } from "react"
import Box from '@mui/material/Box'
import Icon from '@mui/material/Icon'
import FavoriteIcon from '@mui/icons-material/Favorite';

import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PersonTwoToneIcon from '@mui/icons-material/PersonTwoTone';

import LinearProgress from '@mui/material/LinearProgress';

import LoginButton from "./LoginButton.js";
import { ReferenceUserContext } from './UserContextReference.js';
import { Card, CardActionArea, CardContent, CardHeader, Grid, Link, Popover, Stack, Typography } from '@mui/material';
import { ArtistInfo } from './ArtistInfo.js';
import { TopAlbums } from "./TopAlbums.js";
import { TopArtists } from "./TopArtists.js";
import { TrackList } from "./TrackList.js";
import { TrackCountStats } from "./TrackCountStats.js";
import { AnimatedValue } from "./AnimatedValue.js";
import { ToLocaleDateTimeString } from "./42StatsDateTimeUtils.js";
import GenreStats from "./GenreStats.js";
import TrendIn42Stats from "./TrendIn42Stats.js";
import { GetThemableClassName } from "./CssUtils.js";
import { useTheme } from "@emotion/react";


export function GetUserIndicator(value ,index)
{

  const Class= value?.Class?value.Class:value?.Name
  const CardHeaderClass = "Indicator"+Class+'H'
  const CardClass = "Indicator"+Class
  const CardSubtitleClass = "IndicatorSubText"
  let Content=null
  
  if (value?.Type === 1)
  {
    Content=<AnimatedValue Value={value?.Value}/>
  }
  else if (value?.Type===2)
  {
    Content=<> {new Date(new Date().setUTCHours(value.Value)).getHours()}</>
  }
  else if (value?.Type===3)
  {
    Content=<> {Math.round( value.Value*100)/100}</>
  }
  return <Grid key={"UserIndicator_"+ Math.random()} item xs={6} sm={4} md={3} lg={2} >
          
            <Card sx={{minWidth:"200px"}}>
              <CardHeader className={CardHeaderClass} title= {value?.Name} > </CardHeader>
              <CardContent className={CardClass}>
                <Typography variant="h3"> {Content}</Typography>
                <div className={CardSubtitleClass} >{value?.SubText}</div>
              </CardContent>
              <CardActionArea ></CardActionArea>
            </Card>
           
          </Grid>

}

function LastTrack()
{
  const {  ContextState } = useContext(ReferenceUserContext);
  const { DateTime } = require ('luxon');
  const Stats = ContextState?.Stats;
  let TrackTime = null;
  
  
  if (Stats?.LastTrack && Stats?.LastTrack[0].TrackTimeStamp)
  {
    TrackTime =DateTime.fromFormat(Stats?.LastTrack[0].TrackTimeStamp, "y-M-d H:m:s.u", { zone: "UTC" }).setLocale("en-us");
  }
  
  if (Stats?.LastTrack)
  {
     return(
    <Grid id="LasttrackContainer" item xs={12}>
      <Grid container spacing={0} >
        <Grid item xs={12} sm={4}>
          <Box sx={{ display: 'flex' }}>
            {Stats?.LastTrack[0]?.IsFav?
              <div className="TitleFavNormal">
                <Icon>
                  <FavoriteIcon sx={{ color: "red" }}/>
                </Icon>
              </div>:""}
            <Link href={Stats.LastTrack[0].AlbumURL} target='42StatsDeezer' rel="noopener">
              <img src={Stats.LastTrack[0].Cover} alt='' />
            </Link>
          </Box>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Grid container >
            <Grid item xs={12}><div className="RAText">Last track was : {Stats.LastTrack[0].TrackTitle}</div></Grid>
            <Grid item xs={12}>
              <ArtistInfo key={"ArtistInfo"+Math.random()} TrackInfo={Stats.LastTrack[0]} Size={0}/>
            </Grid>
            {!TrackTime?.invalid?
              <Grid item xs={12}><div className="RAText">Played : {TrackTime.toRelative()}</div></Grid>:''}
          </Grid>
        </Grid>
        
      </Grid>
    </Grid>);
  }
  else
  {
    return (<Grid container></Grid>);
  } 

}

export function MeDrawerMenu(open)
{
  const {  ContextState } = useContext(ReferenceUserContext);
  
  let Text = "Me";

  if (ContextState?.UserInfo && ContextState.UserInfo.id)
  {
    Text= ContextState.UserInfo.name;
  }
  return ( 
    <React.Fragment>
      <ListItemIcon
        sx={{
          minHeight: 48,
          justifyContent: open ? 'initial' : 'center',
          px: 2.5,
        }}
      >                   
        <PersonTwoToneIcon/>
      </ListItemIcon>
      <ListItemText primary={Text} sx={{ opacity: open ? 1 : 0 }}/>
    </React.Fragment>
    );
}

function ListenDurationRank(Props)
{
  const Value=Props?.RankingValue;
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
        setTimeout(handlePopoverClose, 2500);
      };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  if (Value)
  {
    return(
      <Grid item className="ListenDurationRank" sx={{width:"7px"}} onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}> 
        <LinearProgress className="RankBar" variant="determinate" value={Value}  />
        <Popover open={open}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            anchorEl={anchorEl}
            onClick={handlePopoverClose}
            >
              <Typography>You are in the top {100-Value} % of users</Typography>
            </Popover>
      </Grid>
    );
  }
  else
  {
    return (<></>)
  }
}

function PlayTime(Props)
{

  return (
    <Grid item className="ListenDuration" xs={Props.Width}>
      <Grid container >
        <ListenDurationRank RankingValue={Props.RankingValue} xs={1}/>
        <Grid  item xs={11}>
          {Props.Description}
          <br/>
          {Props.Duration}
          <br/>
          ( {Props.TrackCount} tracks )
        </Grid>
      </Grid>
    </Grid>
    );
}


function PlayTimes()
{
  const Theme=useTheme()
  const {  ContextState } = useContext(ReferenceUserContext);
  const Stats = ContextState?.Stats;
  const Ranking = Stats?.Rankings;
  const humanizeDuration = require("humanize-duration");
  const { Duration }= require("luxon");
  const { DateTime } = require ('luxon');

  const PlayTimesLabels = ["Today", "This week","This Month", "This year"];
  const CurTime = DateTime.fromJSDate(new Date());
  const CurDayStart = DateTime.fromJSDate(new Date(CurTime.year,CurTime.month-1,CurTime.day));
  const CurWeekStart = CurDayStart.plus({days : - CurDayStart.weekday+1});
  const CurMonthStart = DateTime.fromJSDate(new Date(CurTime.year,CurTime.month-1,1));
  const CurYearStart = DateTime.fromJSDate(new Date(CurTime.year,0,1));
  const PlayTimesStartTime = [CurDayStart,CurWeekStart,CurMonthStart,CurYearStart];

  if (Stats?.PlayTimes)
  {
    const StatsDate = new Date (Stats?.TimeStamp*1000);
  
    let DurationsInfo=Stats.PlayTimes.map( 
      (pt,) =>  {
        
        let RankValue = 0;
        
        if (Ranking && typeof Ranking.Ranks[pt.DurationType] !== "undefined")
        {
            RankValue = 100-Ranking.Classes[Ranking.Ranks[pt.DurationType]];
        }
        let RetVal = {
          Description : PlayTimesLabels[pt.DurationType],
          Duration    : Duration.fromObject({seconds:pt.TrackListenDuration_s}),
          TrackCount  : pt.TrackCount,
          RankingValue : RankValue
        }
        if (PlayTimesStartTime[pt.DurationType] && StatsDate < PlayTimesStartTime[pt.DurationType])
        {
          RetVal.Duration = 'N/A';
          RetVal.TrackCount = 0;
        }

        return RetVal;
      
    } 
    
    )
    
    return(

    <Card  >
      <CardHeader className={GetThemableClassName(Theme,"CardTitle")} title="Listen Durations"/>
      <CardContent>
        <Grid container spacing={3}>
          {DurationsInfo.map( (item)=>
            {
              let DurationString = humanizeDuration(item.Duration,{largest:3});
              //DurationString = DurationString.substring(0,DurationString.length-4);
              return (<React.Fragment key={item.Description}>
                        <PlayTime Width={3} Description={item.Description} Duration={DurationString} TrackCount={item.TrackCount} RankingValue={item.RankingValue}/>
                        </React.Fragment>
                      );
            }
          )}
        </Grid>
      </CardContent>
    </Card>);
  }
  else
  {
    return ('');
  } 
}



function UserIndicators(Props)
{
  const Data=Props.Context?.Stats;

  
  const Indicators=Data?.Indicators?Data.Indicators[0]:null/*[{Name:'Artists', Value:Data?.ArtistCount}
                    ,{Name:'Songs', Value:Data?.TrackCount},
                    {Name:'Albums', Value:Data?.AlbumCount},
                  ]*/
  
  if (!Indicators)
  {
    return null;
  }
  const Content = Indicators.map(GetUserIndicator)
  return (<Grid container justifyContent="center"  spacing={2} >
    {Content}
    </Grid>
  )
}

export default function UserProfile(Props) 
{
  const Theme=useTheme()  
  const {  ContextState } = useContext(ReferenceUserContext);
  const {DateTime} = require ('luxon');
    
  let LastRefresh= 'Stats not build yet...'
  let LastRefreshDurationInfo='Stats not built yet...'
  if (ContextState?.Stats?.TimeStamp)
  {
    try
    {
      LastRefresh  = DateTime.fromFormat(ContextState?.Stats?.TimeStamp,"yyyy-MM-dd HH:mm:ss",{ zone: "UTC" }).toLocal()
      LastRefreshDurationInfo = ToLocaleDateTimeString (LastRefresh) 
    }
    catch 
    {
      LastRefreshDurationInfo='No Data available'
    }
  }
  
  /*useEffect(
    ()=>
    {
      function UpdateRefreshInfo()
      {
        const LastRefresh=DateTime.fromJSDate(new Date(ContextState?.Stats?.TimeStamp*1000))
        let LastRefreshDurationInfo= ToLocaleDateTimeString (LastRefresh) + ' ' +LastRefresh.toRelative()
        
        SetRefreshInfo(LastRefreshDurationInfo)
      }
      const I = setInterval(UpdateRefreshInfo,1000)
      console.log("Setting refresh interval...")
      return ()=>{clearInterval(I)}
    }, [ContextState?.Stats?.TimeStamp]
  )*/

  
  if (ContextState && ContextState?.UserInfo?.id)
  {
    
    return (
    <Stack direction="column" spacing={2} justifyContent="center" alignItems="stretch">

      <Card variant="outlined">
        <CardHeader className={GetThemableClassName(Theme,"CardTitle")} title=  { "Hello " + ContextState.UserInfo.name }/>
        <CardContent>
          <Grid container spacing={2}>
              <Grid item xs={12} sm={9}>
                <LastTrack key="LastTrack"/>
              </Grid>
              <Grid item xs={12} sm={3}>
                 <TrackList MaxTracks = {3} SkipFirst={true}/>
              </Grid>
          </Grid>
        </CardContent>
      </Card>
      <PlayTimes key="PlayTimes"/>
      <TopArtists key="TopArtists"/>
      <TopAlbums key="TopAlbums"/>      
       <Card variant='outlined'>
        <CardHeader className={GetThemableClassName(Theme,"CardTitle")} title=  {'This month stats'}/>
          <CardContent>            
              <UserIndicators Context={ContextState}/>
          </CardContent>
      </Card>
      <GenreStats />
      <TrackCountStats key='TrackCountStats'/>
      <TrendIn42Stats />
      <Typography fontSize={"0.6em"} alignSelf={"flex-end"}>Stats refreshed : {LastRefreshDurationInfo} </Typography>
    </Stack>)
  }
  else
  {
    return (<LoginButton {...Props}/>) 
  }
  
}

