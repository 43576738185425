import React, { useContext, useEffect, useState } from "react";
import { Badge, Grid, LinearProgress, ListItemIcon, ListItemText, Stack, Typography } from "@mui/material";
import DonutLargeIcon from '@mui/icons-material/DonutLarge';

import { ReferenceUserContext } from "./UserContextReference";
import { GetApiURL } from "./UserState";
import UserHistoryRecap from "./UserHistoryRecap";
import { CheckFeatureWasUpdated } from "./42StatsDateTimeUtils";


export function UserHistory2Menu(open)
{
  const {  ContextState } = useContext(ReferenceUserContext);
  
  let Text = "History";

  let FeatureBadgeUpdated = CheckFeatureWasUpdated(ContextState?.Features,1)

  if (ContextState?.UserInfo && ContextState.UserInfo.id)
  {
    return ( 
      <>
        
          <ListItemIcon
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
            }}
          >
          <Badge color="warning" invisible={!FeatureBadgeUpdated}  variant="dot"><DonutLargeIcon /></Badge>
          </ListItemIcon>
        
        <ListItemText primary={Text} sx={{ opacity: open ? 1 : 0 }} />
      </>
    
    );
  }
  else
  {
    return null;
  }
  
}

export default function UserHistory2Panel()
{
  const [URLs, SetURLs]=useState(null)
  const [Info, SetInfo]=useState({State:0})
  
  function GetURLs()
  {

    if (URLs)
    {
      return;
    }
    var opts = {
      method: 'GET',
      //headers: {},
      credentials: 'include'
    };
    
    try {
      let WSUrl = GetApiURL("Verb=GetHistory2");

      fetch(WSUrl, opts).then(function (response) {
        //console.log(response.json);
        return response.clone().json();
      })
        .then(
          function (body) 
          {
            let WSData='';
            //SetCount(Count+1);
            if (body?.Status==="OK") 
            {
              if (body?.UserURLs)
              {
                WSData="Data has been loaded...";
                SetURLs(body.UserURLs);
                SetInfo({State:1,Text:WSData});
              }
              else if ("UserURLs" in  body)
              {
                WSData="Data not loaded : No history available yet. Come back next month... (but listen to music until then)";
                SetInfo({State:2,Text:WSData});
              }
              else
              {
                WSData="Data not loaded : "+JSON.stringify(body);
                SetInfo({State:2,Text:WSData});
              }
              
            }
            else 
            {
              SetInfo({State:2,Text:"No valid data available. Error return by server. Try again after listening to a few songs"});              
            }

          }
        );
    }
    catch (ex) {
      console.log(ex);
    }    
    
  }
  useEffect( GetURLs ,[URLs]);

  if (Info?.State===1)
  {
    //console.log("History Data",URLs)
    return <Grid container alignItems="center" justifyContent="center" direction="row">
        {URLs?.map((v, index)=>{
          return <Grid xs={12} item  key={"HistoryPanel"+Math.random()}><UserHistoryRecap  {...v}/></Grid>
        })}
      </Grid>
        ;
  }
  else if (Info?.State===2)
  {
    return <Stack spacing={2}>
        <Typography>Something unexpected happened</Typography>
        <Typography>{Info?.Text}</Typography>
      </Stack>
  }
  else
  {
    return (
      <Stack flex="Grow">
        <Typography>Data Loading...</Typography>
        <LinearProgress variant="indeterminate" sx={{ width: '100%', borderRadius:'5' }} color="secondary" />
        
      </Stack>
    )
  }
  
}
