import React, {useState } from "react"

import Typography from '@mui/material/Typography';

import MultipleScatterPlots from "./RechartsMultipleLinesScatter.js";



export function UserStatPlot(Props)
{
  const [Datas] = useState(Props?.Datas);
  if (!Datas)
  {
    return (<Typography>No Data.</Typography>)
  }
  
  return <MultipleScatterPlots Datas={Datas} XAxisType="Hour"/>
  
}