import React from "react";
import ComposedPlots from "./RechartsMultipleLinesScatter";

export default function RechartSingleLinePlot(Props)
{
  const Datas= Props.Datas
  const XScaleType = Props.XScaleType

  
  if (Datas )
  {
    return <ComposedPlots Datas={[Datas]} XScaleType={XScaleType} />
  }
  else
  {
    return <></>
  }
}