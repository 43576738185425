import React, {useContext, useState, useEffect} from "react";
import { ReferenceUserContext } from "./UserContextReference";
import HistoryIcon from '@mui/icons-material/History';
import {  Badge, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { LinearProgress } from "@mui/material";
import { AgGridReact } from 'ag-grid-react'; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid



import { TrackHistory2} from './Track.js'
import { GetApiURL } from "./UserState";
import { CheckFeatureWasUpdated, GetLocalDateAtMidnightfromEpoch, ToLocaleDateTimeString } from "./42StatsDateTimeUtils.js";
import { useTheme } from "@emotion/react";


function DateComparator (filterLocalDateAtMidnight,cellValue)
{
  let CellEpoch = parseFloat(cellValue)

  let CellDate =GetLocalDateAtMidnightfromEpoch(1000*CellEpoch)

  return CellDate - filterLocalDateAtMidnight
  
}

function TrackFieldTextMatcher(Track, Field, FilterOption, FilterValue)
{
  let Value = Track[Field]
  if (!Value )
  {
    return false
  }
  try
  {
    Value = Value.toLocaleLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "")
    FilterValue = FilterValue?.toLocaleLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "")
  }
  catch (ex)
  {
    console.log("Exception in comparer",Value, FilterValue)
  }
  switch (FilterOption) {
    case 'contains':
        return Value.indexOf(FilterValue) >= 0;
    case 'notContains':
        return Value.indexOf(FilterValue) < 0;
    case 'equals':
        return Value === FilterValue;
    case 'notEqual':
        return Value !== FilterValue;
    case 'startsWith':
        return Value.indexOf(FilterValue) === 0;
    case 'endsWith':
        const index = Value.lastIndexOf(FilterValue);
        return index >= 0 && index === (Value.length - FilterValue.length);
    default:
        // should never happen
        console.warn('invalid filter type ' + FilterOption);
        return false;
  }
}

function TrackTextMatcher(filterOption)
{
  //console.log("tracktextfilter", filterOption)
  let FOption =filterOption.filterOption
  let TrackData = filterOption.data
  let FilterText = filterOption.filterText

  return TrackFieldTextMatcher(TrackData,'ArtistName',FOption,FilterText) ||
          TrackFieldTextMatcher(TrackData,'TrackTitle',FOption,FilterText) ||
          TrackFieldTextMatcher(TrackData,'AlbumTitle',FOption,FilterText)
  
}

export function UserHistoryMenu(open)
{
  const {  ContextState } = useContext(ReferenceUserContext);
  
  let Text = "History";
  let FeatureBadgeUpdated = CheckFeatureWasUpdated(ContextState?.Features,3)

  if (ContextState?.UserInfo && ContextState.UserInfo.id)
  {
    return ( 
      <>
        <ListItemIcon
          sx={{
            minHeight: 48,
            justifyContent: open ? 'initial' : 'center',
            px: 2.5,
          }}
        >
        <Badge color="warning" invisible={!FeatureBadgeUpdated}  variant="dot">          
          <HistoryIcon />
        </Badge>
        </ListItemIcon>
        <ListItemText primary={Text} sx={{ opacity: open ? 1 : 0 }} />
      </>
    
    );
  }
  else
  {
    return null;
  }
  
}


export default function UserHistoryPanel()
{

  const {  ContextState } = useContext(ReferenceUserContext);
 
  return UserHistoryPanelNew(ContextState?.Stats.LastTrack)
}

function GetTrackCell(Props)
{
  return <TrackHistory2 sx={{display:"flex",alignItems:"center"}}  Track={Props?.data}/>
}

function GetTrackListenDate(Props)
{
  if (Props?.data?.TrackTimeStamp)
  {
    const { DateTime } = require ('luxon');
    let ListenDate =  DateTime.fromJSDate (new Date(Props?.data.TrackTimeStamp*1000));
  
    return <Typography>{ToLocaleDateTimeString( ListenDate)}</Typography>
  }
}

function UserHistoryPanelNew(Tracks)
{
  const [Rows,SetRows] = useState(null);
  const [Info,SetInfo] = useState(0);
  const [CurDataFile,SetCurDataFile] = useState(null);
  const [Progress,SetProgress]=useState(0);
 
  const DataLoading = <>
      <Typography>Data Loading...</Typography>
      <LinearProgress variant="determinate" value={Progress}  sx={{ width: '100%', borderRadius:'5' }} color="secondary" />
    </>
  

  function GetData()
  {
    var opts = {
      method: 'GET',
      //headers: {},
      credentials: 'include'
    };

    function BuildListenCounts()
    {
      if (Rows && (Rows[0].ListenCount!==0))
      {
        //console.log("Rows 0 Listencount",Rows[0].ListenCount,Rows[0])
        let Counts=[]
        //
        let RowIndex = 0
        Rows.forEach((Track)=>
            {
              
              if (Counts[Track.TrackId])
              {
                Counts[Track.TrackId].unshift(RowIndex)
              }
              else
              {
                Counts[Track.TrackId]=[RowIndex]
              }
              RowIndex++
            })
        Rows.forEach((Track)=>
                {
                  let PlayCount = 1
                  let TrackId= Track.TrackId
                  //let DbgTrackId = 2047662477
                  if (TrackId)
                  {
                    /*if (TrackId === DbgTrackId)
                    {
                      console.log("debug build of #1", TrackId, Counts[TrackId])
                    }*/
                    for( let RowIndex of Counts[TrackId])
                    {
                      if (Rows[RowIndex])
                      {
                        Rows[RowIndex].ListenCount=PlayCount
                        PlayCount++
                        /*if (TrackId === DbgTrackId)
                        {
                          console.log("debug build listen count of #",Track.id, TrackId,RowIndex, Rows[RowIndex])
                        }*/
                      }
                    }
                  }
                  
                })
        

      }
    }
    
    try {
      
      let WSUrl = GetApiURL("Verb=GetHistory&V=2&NextPage="+CurDataFile);

      fetch(WSUrl, opts).then(function (response) {
        //console.log(response.json);
        return response.clone().json();
      })
        .then(
          function (body) 
          {
            if (CurDataFile === "Done")
            {
              return
            }
            //SetCount(Count+1);
            //console.log("New HistoryDataLoaded",body)
            if (body?.Status==="OK" && body.UserInfo) 
            {
              if (Progress===0)
              {
                SetProgress(100/body.UserInfo.FileCount)
              }
              else
              {
                SetProgress(Progress+100/body.UserInfo.FileCount)
              }
            
              if (Rows)
              {
                SetRows(Rows.concat(body?.UserInfo.History));
              }
              else
              {
                SetRows(body?.UserInfo.History)
              }

              if (body.UserInfo?.Next)
              {
                //SetCurDataFile("Done")
                SetCurDataFile(body.UserInfo?.Next)
              }
              else
              {
                SetCurDataFile("Done")
                SetInfo({State:1})
                BuildListenCounts()
                
              }

              //console.log("Data recovery info ",Rows?.length)
            }
            else 
            {
              SetCurDataFile("Done")
              SetInfo({State:1,Text:"No History data available. Error return by server. Try again after listening to a few songs"});              
            }

          }
        );
    }
    catch (ex) {
      console.log(ex);
    }  
  }
 

  useEffect( GetData, [Rows,Info,CurDataFile,Progress])
  //useEffect ( BuildListenCounts,[Rows,CurDataFile,Counts])

  // Column Definitions: Defines the columns to be displayed.
  const [colDefs] = useState([
    { field:'TrackTimeStamp', cellRenderer: GetTrackListenDate, 
      headerName:"Listened on ",flex:1, 
      filter: 'agDateColumnFilter',filterParams: {comparator:DateComparator, FilterButtonType:['reset'],maxNumConditions:1},
      floatingFilter: true
    },
    { cellRenderer: GetTrackCell,headerName:"Track",
      flex:5,  sortable:false,field:'TrackTimeStamp',
      filter: 'agTextColumnFilter', filterParams:{textMatcher:TrackTextMatcher, FilterButtonType:['apply','clear','reset'],maxNumConditions:1},
      floatingFilter: true
     },
    
  ]);

  const Theme = useTheme()
  let GridTheme="ag-theme-quartz"
  if (Theme?.palette?.mode==="dark")
  {
     GridTheme="ag-theme-quartz-dark"
  }
  const ViewHeight = window.innerHeight|| document.documentElement.clientHeight|| document.body.clientHeight;
  return (<>
    {!Info?.State?DataLoading:null}
    {Rows?
      <div
        className={GridTheme} // applying the grid theme
        style={{ width: '100%', height:(ViewHeight-100)+"px" }}  //the grid will fill the size of the parent container
        
      >
        <AgGridReact
            rowData={Rows}
            columnDefs={colDefs}
            rowHeight={150}
            ro
        />
      </div>
      :null}
    {Info?.State && !Rows?<Typography>No History data available. Try again after listening to a few songs</Typography>:null}
    </>) 
}


