import React, { useContext } from "react"


import { ReferenceUserContext } from './UserContextReference.js';
import {Avatar, Card, CardContent, CardHeader, CardActions, Grid, Link, List, ListItemText, ListItemAvatar, ListItemButton, Stack, Typography,} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import { GetThemableClassName, GetThemableColor } from "./CssUtils.js";
import { useTheme } from "@emotion/react";


function AlbumComparer(x,y)
{
  let d1 = x?.PlayDuration
  let d2 = y?.PlayDuration
  let c1 = x?.PlayCount
  let c2 = y?.PlayCount

  if (d1 !== d2)
  {
    return d2-d1
  }
  return c2-c1
  
  
}

function AlbumInfo(Props)
{
  if (Props?.Titles)
  {
    return AlbumInfoTDB(Props)
  }
  else
  {
    return AlbumInfoNew(Props)
  }
}

function AlbumInfoTDB(Props)
{
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const HMDuration = require ("humanize-duration");
  const {Duration} = require("luxon")

  const DurationString = HMDuration(Duration.fromObject({seconds:Props.Album.AlbumDuration}),{largest:1});
  const Ranks=["gold","silver"," #967444"];

  const HandleTitleClick = (event, Title) => {
    const win = window.open(Title.TrackURL, '42StatsDeezer');
    win.focus();
  };

  return (
    <React.Fragment>
      <Grid key={Math.random()} className="TopAlbum"  item xs={12} sm={4}  >        
        <Card key={Math.random()}  sx={{"backgroundColor":Ranks[Props.Rank]}}>
          <CardHeader title={Props.Album.Title} />
          <CardContent className="TopAlbum">
            <Grid container>
              <Grid item xs={12}>
                <Link href={Props.Album.AlbumURL} target='42StatsDeezer' rel="noopener">
                  <img src={Props.Album.Image+'?Size=xs'} alt={Props.Album.Title} />
                </Link>
              </Grid>
              
              <Grid item xs={6}>
                <Typography variant='subtitle2'>{Props.Album.AlbumCount} times</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='subtitle2'>{DurationString}</Typography>
              </Grid>        
            </Grid>
          </CardContent>
          <CardActions disableSpacing>
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <List>
                {
                  Props?.Titles?.map((value,index)=>
                  {
                    return (<React.Fragment key={"TATitle_"+Math.random()}>
                      
                      <ListItemButton 
                        onClick={ (event)=> HandleTitleClick(event,value)}>
                        <ListItemAvatar>
                        <Avatar>
                          <img src={value.Picture} width={48} alt='_' />
                        </Avatar>                          
                        </ListItemAvatar>
                        <ListItemText primary={value.TrackTitle} />
                        <ListItemText secondary={value.PlayCount+ ' times'} />
                      </ListItemButton>
                    </React.Fragment>)
                  })
                }
              </List>
                        
            </CardContent>
          </Collapse>
        </Card>
      </Grid>      
    </React.Fragment>
  )
}

function AlbumInfoNew(Props)
{
  const Theme=useTheme()
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const HMDuration = require ("humanize-duration");
  const {Duration} = require("luxon")

  const DurationString = HMDuration(Duration.fromObject({seconds:Props.Album.AlbumDuration}),{largest:1});
  const Ranks=[GetThemableColor(Theme,"gold"),"silver"," #967444"];

  const HandleTitleClick = (event, Title) => {
    const win = window.open(Title.TrackURL, '42StatsDeezer');
    win.focus();
  };

  return (
    <React.Fragment>
      <Grid key={Math.random()} className="TopAlbum"  item xs={12} sm={4}  >      
        <Card xs={12} sm={4} sx={{"backgroundColor":Ranks[Props.Rank]}}>
          <CardHeader title={Props.Album.AlbumTitle} />
          <CardContent className="TopAlbum">
            <Grid container>
              <Grid key={Math.random()} item xs={12}>
                <Link href={Props.Album.AlbumURL} target='42StatsDeezer' rel="noopener">
                  <img src={Props.Album.Cover+'?Size=xs'} alt={Props.Album.Title} />
                </Link>
              </Grid>
              
              <Grid key={Math.random()} item xs={6}>
                <Typography variant='subtitle2'>{Props.Album.AlbumCount} times</Typography>
              </Grid>
              <Grid key={Math.random()} item xs={6}>
                <Typography variant='subtitle2'>{DurationString}</Typography>
              </Grid>        
            </Grid>
          </CardContent>
          <CardActions disableSpacing>
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <List>
                {
                  Props.Album?.titles?.map((value,index)=>
                  {
                    return (<React.Fragment key={"TATitle_"+Math.random()}>
                      
                      <ListItemButton 
                        onClick={ (event)=> HandleTitleClick(event,value)}>
                        <ListItemAvatar>
                        <Avatar>
                          <img src={value.Picture} width={48} alt='_' />
                        </Avatar>                          
                        </ListItemAvatar>
                        <ListItemText key={"TATitle_"+Math.random()} primary={value.TrackTitle} />
                        <ListItemText key={"TATitle_"+Math.random()} secondary={value.PlayCount+ ' times'} />
                      </ListItemButton>
                    </React.Fragment>)
                  })
                }
              </List>
                        
            </CardContent>
          </Collapse>
        </Card>
       </Grid>      
    </React.Fragment>
  )
}

const ExpandMore = styled((props) => {
      const { expand, ...other } = props;
      return <IconButton {...other} />;
    })(({ theme, expand }) => ({
      transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    }));

export function TopAlbums ()
{
  const Theme=useTheme()
  const {ContextState}=useContext(ReferenceUserContext);
  

  const EmptyStats=() => { return (<Typography>No Album stats yet...</Typography>);};
  let Albums=null;

  // Old style TBD when new style running for all
  /*if (ContextState?.Stats && ContextState.Stats?.TopAlbums && ContextState.Stats?.TopAlbums[0] && ContextState.Stats?.TopAlbums[1])
  {
    Albums = ContextState.Stats.TopAlbums[0].map((value,index) => 
      {
        let TitleList = null;

        if (ContextState.Stats.TopAlbums[1] && ContextState.Stats.TopAlbums[1][index])
        {
          TitleList=ContextState.Stats.TopAlbums[1].filter((Track)=>{
            return Track.IdAlbum === value.IdAlbum;
              }
            );
        }
        
        return (
          <AlbumInfo key={"AlbumInfo"+Math.random()} sx={{width:"100%"}}  Album={value} Rank={index} Titles={TitleList}/>
          );
      }
    ) 
  }
  else*/ if (ContextState?.Stats && ContextState.Stats?.TopAlbums && ContextState.Stats?.TopAlbums[0])
  {
    Albums = null
    let AlbumsIdx = []
    ContextState.Stats.TopAlbums[0].map((value,index) => 
      {
        if (!Albums)
        {
          Albums=[]
        }
        if (typeof AlbumsIdx[value.IdAlbum]=='undefined')
        {
          AlbumsIdx[value.IdAlbum]=Object.keys(AlbumsIdx).length
          value.titles=[value]
          value.AlbumDuration=parseInt(value.PlayDuration,10)
          value.AlbumCount=parseInt(value.PlayCount,10)
          Albums.push(value)
        }
        else
        {
          Albums[AlbumsIdx[value.IdAlbum]].titles.push(value)
          Albums[AlbumsIdx[value.IdAlbum]].AlbumDuration+=parseInt(value.PlayDuration,10)
          Albums[AlbumsIdx[value.IdAlbum]].AlbumCount+=parseInt(value.PlayCount,10)
        }
        return null
      })
    if (Albums)
    {
      Albums = Array.from (Albums)
      Albums.sort(AlbumComparer)
      Albums = Albums.map ((value,index)=>
          {
              return (
              <AlbumInfo key={"AlbumInfo"+Math.random()} sx={{width:"100%"}}  Album={value} Rank={index} />
              );
          })
    }
    else
    {
      return <Typography>Not enough data</Typography>
    }
  }
  
  return (
    <React.Fragment>
      <Card variant="outlined" key="TopAlbumsCard" >
        <CardHeader className={GetThemableClassName(Theme,"CardTitle")} title=  "Top Albums">
        </CardHeader>
        <CardContent>
          <Stack direction="row" justifyContent="space-evenly" alignItems="baseline" spacing={2}>
                {Albums?Albums:EmptyStats()}              
          </Stack>
        </CardContent>
        
      </Card>
    </React.Fragment>
  )

}