import humanizeDuration from "humanize-duration"
import { Duration } from "luxon"
import React, { useEffect, useState } from "react"
import {Waypoint} from 'react-waypoint'

 export function AnimatedValue (Props)
{

  const [Animate,SetAnimate]=useState(Props?.AutoStart?Props.AutoStart:false)
  const [Value,SetValue]=useState (0)
  
  
  const Inc = 0.005
  
  function easeOutQuad(x)
  {
    return 1 - (1 - x) * (1 - x);
  }

  
  function StartAnim()
  {
    SetAnimate(true)
  }
  
  function StopAnim()
  {
    SetAnimate(false)
  }
  
  useEffect ( ()=> {

      if (Animate && (Value < 1))
      {
        setTimeout( () =>{SetValue(Value+Inc)},5)
      }
      else if (!Animate)
      {
        SetAnimate(false)
      }
      
    
  },[Props?.Value,Value, Animate, SetAnimate])

  
  let ValueString = Math.round(Props?.Value*easeOutQuad(Value))
  if (Props?.IsDuration)
  {
    ValueString = humanizeDuration( Duration.fromObject({seconds:Value*Props?.Value}),{largest:2})
  }


  return (<><Waypoint 
              onEnter={StartAnim}
              onLeave={StopAnim} /> {ValueString}</>)
}