
import { createContext, useState } from "react";

// Create the context
export const ReferenceUserContext = createContext();

export const ReferenceUserContextProvider = ({ children }) => {
  // This state stores the user session
  const [ContextState,  SetContextState] = useState(null)

  // Here you could do some useEffect to try to auth the user automatically or something...
  // ...

  // Return the provider with the user and its setter
  return <ReferenceUserContext.Provider value={{ ContextState,  SetContextState }} >
    {children}
  </ReferenceUserContext.Provider>

}