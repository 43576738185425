import Main from './Main.js';
import './App.css';
import { ReferenceUserContextProvider } from './UserContextReference.js'
import {  useState } from 'react';
import { ThemeProvider, createTheme, useMediaQuery } from '@mui/material';


// Define theme settings
const light = {
  palette: {
    mode: "light",
  },
};

const dark = {
  palette: {
    mode: "dark",
    text: {
      primary: '#E4E4E0',
    },
    contrastThreshold : 4

  },
};

 export default function App() 
{
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  // The light theme is used by default
  const [isDarkTheme, setIsDarkTheme] = useState(prefersDarkMode);
  const [OverRidePref, SetOverRidePref] = useState(false)
  const [OverRideThemeIsDark, SetOverRideThemeIsDark] = useState(false)

  // This function is triggered when the Switch component is toggled
  const changeTheme = () => {
    console.log("changeTehme",!isDarkTheme)
    //setIsDarkTheme(!isDarkTheme);
    SetOverRidePref(true);
    SetOverRideThemeIsDark(!isDarkTheme);
    let Mode = !isDarkTheme?2:1
    SetDarkMode(Mode,true)
  };

  const SetDarkMode = (mode,override) => 
  {
    console.log("SetDarkMode",mode,override,OverRidePref,OverRideThemeIsDark)
    if (OverRidePref)
    {
      setIsDarkTheme(OverRideThemeIsDark)
      if (((mode === '2') && OverRideThemeIsDark) || (mode === '1' && !OverRidePref))
      {
        console.log("SetDarkMode - disable override")
        SetOverRidePref(false)
      }
      return
    }

    switch (mode)
    {
      case "2":
        if(!isDarkTheme )
        {           
          setIsDarkTheme(true);    
        }
        break;
      
      case "1":
        if (isDarkTheme)
        {
          setIsDarkTheme(false);    
        }
        break;
      
      default:
        if (prefersDarkMode!==isDarkTheme) 
        {
          setIsDarkTheme(prefersDarkMode);    
        }
        break;
      
    }
    
  };

  const DarkTheme=createTheme(dark)
  const LightTheme = createTheme(light)
  return (
    <ThemeProvider theme={isDarkTheme ? DarkTheme : LightTheme}>
      <ReferenceUserContextProvider >
        <div className="App">
          <Main ChangeDarkMode={changeTheme} SetDarkMode={SetDarkMode} IsDarkTheme={isDarkTheme} />
        </div>
      </ReferenceUserContextProvider>
    </ThemeProvider>
  );
}
